import fetch from '../utils/fetchMapi'

export function saveArticleCategory (query) {
  return fetch({
    url: '/admin/fpc/create_update',
    method: 'post',
    params: query
  })
}

export function getCategoryList (query) {
  return fetch({
    url: '/admin/fpc/get_fpc_category',
    method: 'get',
    params: query
  })
}


export function deleteArticleCategoryInfo (id) {
  return fetch({
    url: 'admin/fpc/del?id=' + id,
    method: 'post'
  })
}



//以下为账号管理接口

export function createContent (query) {
  console.log(query);
  return fetch({
    url: '/admin/fpc/content/create_update',
    method: 'post',
    params: query
  })
}


export function getAccountList (query) {
  return fetch({
    url: '/admin/fpc/get_contents',
    method: 'get',
    params: query
  })
}
export function changeRecommend (query) {
  return fetch({
    url: '/admin/fpc/recommend',
    method: 'post',
    params: query
  })
}

export function changeIsSearch (query) {
  return fetch({
    url: '/admin/fpc/is_search',
    method: 'post',
    params: query
  })
}

export function changeIsShow (query) {
  return fetch({
    url: '/admin/fpc/is_show',
    method: 'post',
    params: query
  })
}

export function updateContent (id, query) {
  return fetch({
    url: '/admin/fpc/content/create_update?id=' + id,
    method: 'post',
    params: query
  })
}

export function deleteContent (id) {
  return fetch({
    url: 'admin/fpc/del_content?id=' + id,
    method: 'post'
  })
}


export function getWnzwSeatchList(){
  return fetch({
    url:'admin/wnzw/search-list',
    method:'get'
  })
}